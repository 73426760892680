<template>
	<div class="cct">

 <d-player id="dplayer" ref="player" class="dplayer video-box" :options="optionsPlayer"></d-player>
</div>

</template>

<script> 
import Hls from "hls.js"; 
import VueDPlayer from "vue-dplayer";
import "vue-dplayer/dist/vue-dplayer.css";

	
	import {
		transmitterData,
		emailLogin,
		getIsRoom
	} from '../../api';

	export default {
		  components: {
		    "d-player": VueDPlayer,
		  },

		data() {
			return {
			 optionsPlayer: {
			        container: this.$refs.player, //播放器容器
			        mutex: false, //  防止同时播放多个用户，在该用户开始播放时暂停其他用户
			        theme: "#b7daff", // 风格颜色，例如播放条，音量条的颜色
			        loop: false, // 是否自动循环
			        lang: "zh-cn", // 语言，'en', 'zh-cn', 'zh-tw'
			        screenshot: true, // 是否允许截图（按钮），点击可以自动将截图下载到本地
			        hotkey: true, // 是否支持热键，调节音量，播放，暂停等
			        preload: "auto", // 自动预加载
			        volume: 0.7, // 初始化音量
			        playbackSpeed: [0.5, 0.75, 1, 1.25, 1.5, 2, 3], //可选的播放速度，可自定义
			        logo: "https://qczh-1252727916.cos.ap-nanjing.myqcloud.com/pic/273658f508d04d488414fd2b84c9f923.png", // 在视频左上角上打一个logo
			        video: {
			          type: "customHls",
			          url: "https://cctvwbcdcnc.v.wscdns.com/cctvwbcd/cdrmcctv1_1_720P/playlist.m3u8?wsApp=HLS", // 播放视频的路径
			          customType: {
			            customHls: function (video, player) {
			              const hls = new Hls();
			              hls.loadSource(video.src);
			              hls.attachMedia(video);
			            },
			          },
			          // quality: [
			          // 设置多个质量的视频
			          // {
			          //   name: "高清",
			          //   url:
			          //     "https://api.dogecloud.com/player/get.mp4?vcode=5ac682e6f8231991&userId=17&ext=.mp4",
			          //   type: "auto" // 'auto', 'hls', 'flv', 'dash', 'webtorrent', 'normal' 或 其他自定义类型
			          // },
			          // {
			          //   name: "标清",
			          //   url:
			          //     "https://api.dogecloud.com/player/get.mp4?vcode=5ac682e6f8231991&userId=17&ext=.mp4",
			          //   type: "auto"
			          // }
			          // ],
			          defaultQuality: 0, // 默认是高清
			          pic: "", // 视频封面图片
			          thumbnails: "https://cn.bing.com/th?id=OHR.MeotoIwa_ZH-CN3126370410_1920x1080.jpg&rf=LaDigue_1920x1080.jpg", // 进度条上的缩略图,需要通过dplayer-thumbnails来生成
			        },
			        // subtitle: {
			        //   //字幕
			        //   url: "", //字幕网址
			        //   color: "#fff", //字幕颜色
			        //   fontSize: "20px",
			        //   bottom: "40px"
			        // },
			        // danmaku: {
			        //   // 弹幕
			        //   id: "9E2E3368B56CDBB4",
			        //   api: "https://api.prprpr.me/dplayer/",
			        //   token: "tokendemo", //后端验证令牌
			        //   maximum: 1000, //弹幕最大数量
			        //   addition: ["https://api.prprpr.me/dplayer/v3/bilibili?aid=4157142"], //其他弹幕
			        //   user: "DIYgod", //弹幕用户名
			        //   bottom: "15%", //值，例如：10px，10％，即弹幕底部和播放器底部之间的距离，以防止遮挡字幕
			        //   unlimited: true //即使弹幕重叠也显示所有弹幕
			        // },
			        contextmenu: [
			          //  自定义上下文菜单
			          // 右键菜单
			          {
			            text: "选项二",
			            click: (player) => {
			            },
			          },
			        ],
			        highlight: [
			          //进度条上的自定义时间标记
			          // 进度条时间点高亮
			          {
			            text: "10M",
			            time: 6,
			          },
			          {
			            text: "20M",
			            time: 12,
			          },
			        ],
			      }

			}
		},
		
		methods: {
		
			},
		mounted() {

		},

		computed: {
	
			cover() {
				var cover = "";
				this.$store.state.blogInfo.pageList.forEach(item => {
					if (item.pageLabel === "album") {
						cover = item.pageCover;
					}
				});
				return "background: url(" + cover + ") center center / cover no-repeat";
			}
		}
	};
</script>

<style scoped>
	* {}

	#videoPlayer2 {
		width: 100%;
		height: 100%;
	}

	.album-item {
		overflow: hidden;
		position: relative;
		cursor: pointer;
		background: #000;
		border-radius: 0.5rem !important;
	}

	.album-cover {
		position: relative;
		max-width: none;
		width: calc(100% + 1.25rem);
		height: 250px;
		opacity: 0.8;
		transition: opacity 0.35s, transform 0.35s;
		transform: translate3d(-10px, 0, 0);
		object-fit: cover;
	}

	.album-wrapper {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 1.8rem 2rem;
		color: #fff !important;
	}

	.album-item:hover .album-cover {
		transform: translate3d(0, 0, 0);
		opacity: 0.4;
	}

	.album-item:hover .album-name:after {
		transform: translate3d(0, 0, 0);
	}

	.album-item:hover .album-desc {
		opacity: 1;
		filter: none;
		transform: translate3d(0, 0, 0);
	}

	.album-name {
		font-weight: bold;
		font-size: 1.25rem;
		overflow: hidden;
		padding: 0.7rem 0;
		position: relative;
	}

	.album-name:after {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 2px;
		background: #fff;
		content: "";
		transition: transform 0.35s;
		transform: translate3d(-101%, 0, 0);
	}

	.album-desc {
		margin: 0;
		padding: 0.4rem 0 0;
		line-height: 1.5;
		opacity: 0;
		transition: opacity 0.35s, transform 0.35s;
		transform: translate3d(100%, 0, 0);
	}

	.ltAitabie {
		border: none;
	}

	.cct {
		width: 100%;
		height: 100%;
	}

	.fasong {
		height: 34px;
		width: 10%;
		background-color: #e8e8e8;
		text-align: center;
		line-height: 37px;
		border-radius: 4px;
		color: black;
		align-self: flex-end;
		font-size: 9%;
		cursor: pointer;
		float: right;
	}

	.marquee {
		position: absolute;
		top: 10px;
		width: 100%;
	}


	
#videoPlayer {
 width: 500px;
 height: 300px;
 margin: 50px auto;
}
</style>
